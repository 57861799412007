import {
    SEND_FORGET_PASSWORD_EMAIL,
    UPDATE_AUTH_USER,
    UPDATE_LOAD_USER,
    UPDATE_AUTH_USER_TOKEN,
    UPDATE_AUTH_USER_ORDER_ID,
    UPDATE_INITIAL_ORDER_CONFIG
} from "../../@jumbo/constants/ActionTypes";

export const setAuthUser = user => {
    return dispatch => {
        dispatch({
            type: UPDATE_AUTH_USER,
            payload: user
        });
    };
};

export const setAuthUserToken = token => {
    return dispatch => {
        dispatch({
            type: UPDATE_AUTH_USER_TOKEN,
            payload: token
        });
    };
}

export const setAuthUserOrderID = orderID => {
    return dispatch => {
        dispatch({
            type: UPDATE_AUTH_USER_ORDER_ID,
            payload: orderID
        });
    };
}

export const setAuthUserInitialOrderConfiguration = config => {
    return dispatch => {
        dispatch({
            type: UPDATE_INITIAL_ORDER_CONFIG,
            payload: config
        })
    }
}

export const updateLoadUser = loading => {
    return dispatch => {
        dispatch({
            type: UPDATE_LOAD_USER,
            payload: loading
        });
    };
};

export const setForgetPassMailSent = status => {
    return dispatch => {
        dispatch({
            type: SEND_FORGET_PASSWORD_EMAIL,
            payload: status
        });
    };
};
