import React, {lazy, Suspense} from "react"
import {Redirect, Route, Switch} from "react-router"
import PageLoader from "../../@jumbo/components/PageComponents/PageLoader"

const DataSection = ({match}) => {
    const requestedUrl = match.url.replace(/\/$/, "")

    return (
        <Suspense fallback={<PageLoader/>}>
            <Switch>
                <Redirect
                    exact
                    from={`${requestedUrl}/`}
                    to={`${requestedUrl}/prospects`}
                />
                <Route
                    path={`${requestedUrl}/prospects`}
                    component={lazy(() => import("./Prospects"))}
                />
                <Route
                    path={`${requestedUrl}/localseo`}
                    component={lazy(() => import("./LocalSEO"))}
                />
                <Route component={lazy(() => import("../ExtraPages/404"))}/>
            </Switch>
        </Suspense>
    )
}

export default DataSection
