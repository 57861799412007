/**
 * Calculates the x-axis value of the center of the marker on a SVG Map
 * @param long Longitude of the place
 * @param a Leftmost longitude of the country that the place belongs to (From geoViewBox variable of the SVG Map)
 * @param c Rightmost longitude of the country that the place belongs to (From geoViewBox variable of the SVG Map)
 * @param width Width value from the viewbox variable of the SVG Map
 * @returns {number}
 */
export const calculateMarkerX = (long, a, c, width) => Math.floor(((long - a) / (c - a)) * width)

/**
 * Calculates the y-axis value of the center of the marker on a SVG Map
 * @param lat Latitude of the place
 * @param b Topmost longitude of the country that the place belongs to (From geoViewBox variable of the SVG Map)
 * @param d Bottommost longitude of the country that the place belongs to (From geoViewBox variable of the SVG Map)
 * @param height Height value from the viewbox variable of the SVG Map
 * @returns {number}
 */
export const calculateMarkerY = (lat, b, d, height) => Math.floor(((lat - b) / (d - b)) * height)

/**
 * Calculates the length of a country/place in Kilometers from the latitude value
 * @param b Topmost latitude of the country/place (From geoViewBox variable of the SVG Map)
 * @param d Bottommost latitude of the country/place (From geoViewBox variable of the SVG Map)
 * @returns {number}
 */
export const calculateHeightFromLatitude = (b, d) => Math.floor(Math.abs(b - d) * 69.44 * 1.61)

/**
 *
 * @param radius Actual radius in Kilometers
 * @param b Topmost longitude of the country that the place belongs to (From geoViewBox variable of the SVG Map)
 * @param d Bottommost longitude of the country that the place belongs to (From geoViewBox variable of the SVG Map)
 * @param height Height value from the viewbox variable of the SVG Map
 * @returns {number}
 */
export const calculateMarkerRadiusInPixels = (radius, b, d, height) => (height / calculateHeightFromLatitude(b, d)) * radius

/**
 *
 * @param menuOption
 * @returns {string}
 */
export const getInternalURLFromMenuOption = (menuOption) => {
    let url = ""

    switch (menuOption) {
        case "home":
            url = "/home"
            break
        case "trends":
            url = "/extra-pages/trends"
            break
        case "import":
            url = "/extra-pages/import"
            break
        case "export":
            url = "/extra-pages/export"
            break
        case "crm":
            url = "/extra-pages/crm"
            break
        case "enrich":
            url = "/extra-pages/enrich"
            break
        case "smc":
            url = "/extra-pages/smc"
            break
        case "oct":
            url = "/extra-pages/oct"
            break
        case "cct":
            url = "/extra-pages/cct"
            break
        case "mpa":
            url = "/extra-pages/mpa"
            break
        case "cst":
            url = "/extra-pages/cst"
            break
        case "profiling":
            url = "/extra-pages/profiling"
            break
        case "forms":
            url = "/extra-pages/forms"
            break
        case "prospects":
            url = "/data/prospects"
            break
        case "updates":
            url = "/extra-pages/updates"
            break
        case "profile":
            url = "/extra-pages/manage-profile"
            break
        case "users":
            url = "/extra-pages/manage-users"
            break
        case "orders":
            url = "/extra-pages/view-orders"
            break
        case "logout":
            url = "/extra-pages/error-454"
            break
        case "handbook":
            url = "/extra-pages/handbook"
            break
        case "tool":
            url = "/extra-pages/support-tool"
            break
        case "data":
            url = "/extra-pages/support-data"
            break
        case "finance":
            url = "/extra-pages/support-finance"
            break
        case "chat":
            url = "/extra-pages/chat"
            break
        case "contact":
            url = "/extra-pages/contact"
            break
        default:
            url = ""
    }

    return url
}

export const filterRadiusFromCityId = cityId => {
    if (cityId.includes("R"))
        return parseInt(cityId.split("R")[1])
    else return parseInt(cityId.split("r")[1])
}