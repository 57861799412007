import axios from "./config"

const ProspectsAPI = {
    updatePrice: async (token, i, o, s) => {
        // t= token
        // i= order id
        // o= basic/full
        // s= step name

        let response = await axios
            .request({
                method: "GET",
                params: {
                    action: "update_price",
                    t: token,
                    i: i,
                    o: o,
                    s: s
                }
            })
        return response.data
    },
    updatePriceOnSTEP5: async (t, i, b, v) => {
        // t= token
        // i= order id
        // o= basic/full
        // s= step name
        // b= province id
        // v= (1=add, 0=remove)

        let response = await axios
            .request({
                method: "GET",
                params: {
                    action: "update_price",
                    t: t,
                    i: i,
                    o: "basic",
                    s: "step_5",
                    b: b,
                    v: v
                }
            })
        return response.data
    },
    updatePriceOnSTEP2ButtonClicked: async (t, i, v) => {
        // t= token
        // i= order id
        // o= basic/full
        // s= step name
        // v= button id

        let response = await axios
            .request({
                method: "GET",
                params: {
                    action: "update_price",
                    t: t,
                    i: i,
                    o: "basic",
                    s: "step_2",
                    v: v
                }
            })
        return response.data
    },
    addBrancheOnSTEP3: async (t, i, b, step = 'step_3') => {
        // t= token
        // i= order id
        // b= data1+data2
        // o= basic/full
        // s= step name
        // v= 0:remove 1:add

        let response = await axios
            .request({
                method: "GET",
                params: {
                    action: "update_price",
                    t: t,
                    i: i,
                    b: b,
                    o: "basic",
                    s: step,
                    v: 1
                }
            })
        return response.data
    },
    removeBrancheOnSTEP3: async (t, i, b, step = 'step_3') => {
        // t= token
        // i= order id
        // b= data1+data2
        // o= basic/full
        // s= step name
        // v= 0:remove 1:add

        let response = await axios
            .request({
                method: "GET",
                params: {
                    action: "update_price",
                    t: t,
                    i: i,
                    b: b,
                    o: "basic",
                    s: step,
                    v: 0
                }
            })
        return response.data
    },
    addSizeOnSTEP4: async (t, i, b) => {
        // t= token
        // i= order id
        // b= data1+data2
        // o= basic/full
        // s= step name
        // v= 0:remove 1:add

        let response = await axios
            .request({
                method: "GET",
                params: {
                    action: "update_price",
                    t: t,
                    i: i,
                    b: b,
                    o: "basic",
                    s: "step_4",
                    v: 1
                }
            })
        return response.data
    },
    removeSizeOnSTEP4: async (t, i, b) => {
        // t= token
        // i= order id
        // b= data1+data2
        // o= basic/full
        // s= step name
        // v= 0:remove 1:add

        let response = await axios
            .request({
                method: "GET",
                params: {
                    action: "update_price",
                    t: t,
                    i: i,
                    b: b,
                    o: "basic",
                    s: "step_4",
                    v: 0
                }
            })
        return response.data
    },
    getBranchOptions: async (token, q) => {
        // t= token
        // q= query

        let response = await axios
            .request({
                method: "GET",
                params: {
                    action: "branche_select",
                    t: token,
                    q: q
                }
            })
        return response.data
    },
    getCityOptions: async (t, i, q) => {
        // t= token
        // i=order id
        // q= query text

        let response = await axios
            .request({
                method: "GET",
                params: {
                    action: "city_select",
                    t: t,
                    i: i,
                    q: q
                }
            })
        return response.data
    },
    getCompanyOptions: async (t, q) => {
        // t= token
        // q= query text

        let response = await axios
            .request({
                method: "GET",
                params: {
                    action: "company_select",
                    t: t,
                    q: q
                }
            })
        return response.data
    },
    getCompany: async (t, i) => {
        // t= token
        // i= company id

        let response = await axios
            .request({
                method: "GET",
                params: {
                    action: "get_company",
                    t: t,
                    i: i
                }
            })
        return response.data
    },
    makePayment: async (t, i, data) => {
        // t= token
        // i= order id

        let response = await axios
            .request({
                    method: "POST",
                    params: {
                        action: "start_payment",
                        t: t,
                        i: i
                    },
                    data: data,
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                }
            )
        return response.data
    },
    onCountrySelect: async (t, q, i) => {
        // t= token
        // q= country code (e.g.- NL/BE)
        // i= order id

        let response = await axios
            .request({
                method: "GET",
                params: {
                    action: "county_select",
                    t: t,
                    q: q,
                    i: i
                }
            })
        return response.data
    }
}

export default ProspectsAPI