import {combineReducers} from "redux";
import {connectRouter} from "connected-react-router";

import Common from "./Common";
import Dashboard from "./Dashboard";
import ContactApp from "./ContactApp";
import Auth from "./Auth";
import Footer from "./Footer"
import Prospect from "./Prospect"
import Navigation from "./Navigation"

export default history =>
    combineReducers({
        router: connectRouter(history),
        common: Common,
        dashboard: Dashboard,
        auth: Auth,
        contactApp: ContactApp,
        footer: Footer,
        prospect: Prospect,
        navigation: Navigation
    });
