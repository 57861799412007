import React, {lazy, Suspense} from 'react';
import {Redirect, Route, Switch} from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';

const Main = ({match}) => {
    const requestedUrl = match.url.replace(/\/$/, '');
    return (
        <Suspense fallback={<PageLoader/>}>
            <Switch>
                <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/home`}/>
                <Route path={`${requestedUrl}/home`} component={lazy(() => import('./Home'))}/>
                <Route component={lazy(() => import('../ExtraPages/404'))}/>
            </Switch>
        </Suspense>
    );
};

export default Main;
