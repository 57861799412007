export const DRAWER_BREAK_POINT = {
    SX: "sx",
    SM: "sm",
    MD: "md"
};

export const HEADER_TYPE = {
    FIXED: "fixed",
    STATIC: "static"
};

export const SIDEBAR_TYPE = {
    FULL: "full",
    MINI: "mini",
    DRAWER: "drawer"
};
export const Fonts = {
    PRIMARY: "Normal",
    ROBOTO: "Roboto"
};

export const THEME_TYPES = {
    DARK: "dark",
    SEMI_DARK: "semi-dark",
    LIGHT: "light"
};

export const LAYOUT_STYLES = {
    BOXED: "boxed",
    FULL_WIDTH: "full-width",
    FRAMED: "framed"
};

export const LAYOUT_TYPES = {
    VERTICAL_DEFAULT: "vertical-default",
    VERTICAL_MINIMAL: "vertical-minimal",
    VERTICAL_MINIMAL_NO_HEADER: "vertical-minimal-no-header",
    VERTICAL_MODERN_SIDEBAR: "vertical-modern-sidebar",
    HORIZONTAL_DEFAULT: "horizontal-default",
    HORIZONTAL_DARK: "horizontal-dark",
    HORIZONTAL_MINIMAL: "horizontal-minimal",
    HORIZONTAL_TOP_MENU: "horizontal-top-menu"
};

export const SIDEBAR_WIDTH = {
    SMALL: 280,
    DEFAULT: 304,
    WIDE: 330
};
