import {
    UPDATE_STEP1_DATA,
    UPDATE_STEP2_DATA,
    UPDATE_STEP3_DATA,
    UPDATE_STEP4_DATA,
    UPDATE_STEP5_DATA,
    UPDATE_STEP6_DATA,
    UPDATE_STEP7_DATA,
    UPDATE_STEP7_FORM_DATA,
    UPDATE_PAYMENT_BUTTON_ID,
    UPDATE_STEP5_MARKERS,
    UPDATE_STEP5_ALL_LOCATIONS,
    UPDATE_STEP5_SELECTED_COUNTRY
} from "../../@jumbo/constants/ActionTypes"

export const setSTEP1Data = data => {
    return dispatch => {
        dispatch({
            type: UPDATE_STEP1_DATA,
            payload: data
        })
    }
}

export const setSTEP2Data = data => {
    return dispatch => {
        dispatch({
            type: UPDATE_STEP2_DATA,
            payload: data
        })
    }
}

export const setSTEP3Data = data => {
    return dispatch => {
        dispatch({
            type: UPDATE_STEP3_DATA,
            payload: data
        })
    }
}

export const setSTEP4Data = data => {
    return dispatch => {
        dispatch({
            type: UPDATE_STEP4_DATA,
            payload: data
        })
    }
}

export const setSTEP5Data = data => {
    return dispatch => {
        dispatch({
            type: UPDATE_STEP5_DATA,
            payload: data
        })
    }
}

export const setSTEP5Markers = data => {
    return dispatch => {
        dispatch({
            type: UPDATE_STEP5_MARKERS,
            payload: data
        })
    }
}

export const setSTEP5AllLocations = data => {
    return dispatch => {
        dispatch({
            type: UPDATE_STEP5_ALL_LOCATIONS,
            payload: data
        })
    }
}

export const setSTEP5SelectedCountry = data => {
    return dispatch => {
        dispatch({
            type: UPDATE_STEP5_SELECTED_COUNTRY,
            payload: data
        })
    }
}

export const setSTEP6Data = data => {
    return dispatch => {
        dispatch({
            type: UPDATE_STEP6_DATA,
            payload: data
        })
    }
}

export const setSTEP7Data = data => {
    return dispatch => {
        dispatch({
            type: UPDATE_STEP7_DATA,
            payload: data
        })
    }
}

export const setSTEP7FormData = data => {
    return dispatch => {
        dispatch({
            type: UPDATE_STEP7_FORM_DATA,
            payload: data
        })
    }
}

export const setPaymentButtonID = data => {
    return dispatch => {
        dispatch({
            type: UPDATE_PAYMENT_BUTTON_ID,
            payload: data
        })
    }
}