import {setFooter, setFooterOnlyProspect, setFooterTableState} from "../redux/actions/Footer"
import PropTypes from "prop-types"

export function initFooterData(data, dispatch, setTableState) {
    const prospectData = data["prospects_counts"]

    let footerData = {
        footerLogoText: data["footer_logo_text"],
        footerText: data["footer_text"],
        footerButtonText: data["footer_button_text"],
        footerButtonURL: data["footer_button_url"],
        footerValue: data["footer_value"],
        footerButtonMsg: data["footer_button_msg"],
        prospect: {
            count: prospectData["count"],
            amountEx: prospectData["amount_ex"],
            vatText: prospectData["vat_text"],
            amountVAT: prospectData["amount_vat"],
            totalText: prospectData["total_text"],
            amountInc: prospectData["amount_inc"],
            buttonText: prospectData["button_text"],
            buttonURL: prospectData["button_url"]
        }
    }

    dispatch(setFooter(footerData))

    if (setTableState !== null)
        dispatch(setFooterTableState(setTableState))
}

export default function handleFooterData(data, dispatch, setTableState) {
    const prospectData = data["prospects_counts"]

    let prospect = {
        count: prospectData["count"],
        amountEx: prospectData["amount_ex"],
        vatText: prospectData["vat_text"],
        amountVAT: prospectData["amount_vat"],
        totalText: prospectData["total_text"],
        amountInc: prospectData["amount_inc"],
        buttonText: prospectData["button_text"],
        buttonURL: prospectData["button_url"]
    }

    dispatch(setFooterOnlyProspect(prospect))

    if (setTableState !== null)
        dispatch(setFooterTableState(setTableState))
}

handleFooterData.propTypes = {
    data: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    setTableState: PropTypes.bool.isRequired
}