import React, {useContext, useEffect, useState} from "react"
import {Box, Hidden} from "@material-ui/core"
import clsx from "clsx"
import AppContext from "../../../contextProvider/AppContextProvider/AppContext"
import CmtVerticalLayout from "../../../../../@coremat/CmtLayouts/Vertical"
import CmtSidebar from "../../../../../@coremat/CmtLayouts/Vertical/Sidebar"
import SidebarHeader from "../../partials/SidebarHeader"
import SideBar from "../../partials/SideBar"
import CmtContent from "../../../../../@coremat/CmtLayouts/Vertical/Content"
import ContentLoader from "../../../ContentLoader"
import {HEADER_TYPE, SIDEBAR_TYPE} from "../../../../constants/ThemeOptions"
import CmtHeader from "../../../../../@coremat/CmtLayouts/Vertical/Header"
import makeStyles from "@material-ui/core/styles/makeStyles"
import SidebarToggleHandler from "../../../../../@coremat/CmtLayouts/Vertical/SidebarToggleHandler"
import CmtFooter from "../../../../../@coremat/CmtLayouts/Vertical/Footer"
import Footer from "../../partials/Footer"
import {useSelector} from "react-redux"
import JSON5 from "json5"

const useStyles = makeStyles(theme => ({
    minimalNoHeader: {
        backgroundColor: "transparent",
        boxShadow: "none",
        "& .Cmt-toggle-menu": {
            color: theme.palette.text.primary,
            marginLeft: 15
        }
    }
}))

const MinimalNoHeader = ({className, children}) => {
    const classes = useStyles();
    const {
        drawerBreakPoint,
        isSidebarFixed,
        sidebarStyle,
        sidebarSize
    } = useContext(AppContext);
    const {authUser} = useSelector(({auth}) => auth)
    const [showMenu, setShowMenu] = useState(true)
    const [showFooter, setShowFooter] = useState(false)
    const [headerMargin, setHeaderMargin] = useState(0)

    useEffect(() => {
        if (authUser) {
            let settings = authUser["settings"]
            settings = JSON5.parse(settings)

            setShowMenu(settings["showMenu"])
            setShowFooter(settings["showFooter"])

            setHeaderMargin(parseFloat(settings["header_margin"]))
        }
    }, [authUser])


    return (
        <CmtVerticalLayout
            drawerBreakPoint={showMenu ? drawerBreakPoint : null}
            className={clsx("verticalMinimalNoHeaderLayout", className)}
            sidebarWidth={showMenu ? sidebarSize : 0}
        >
            <CmtHeader className={classes.minimalNoHeader} type={HEADER_TYPE.STATIC}>
                <Hidden lgUp>
                    <SidebarToggleHandler
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                    />
                </Hidden>
            </CmtHeader>
            {
                showMenu ?
                    <CmtSidebar
                        type={SIDEBAR_TYPE.MINI}
                        isSidebarFixed={isSidebarFixed}
                        {...sidebarStyle}
                    >
                        <SidebarHeader/>
                        <SideBar/>
                    </CmtSidebar> : null
            }
            <CmtContent>
                <Box
                    style={{width: "100%", backgroundColor: "transparent"}}
                    height={headerMargin}
                />
                {children}
                <ContentLoader/>
            </CmtContent>
            {showFooter && (
                <CmtFooter type="static">
                    <Footer/>
                </CmtFooter>
            )}
        </CmtVerticalLayout>
    );
};

export default MinimalNoHeader
